<style scoped lang="less">
  .content {
    padding: 20px;
    box-sizing: border-box;
  }
  .text-field, .text-value {
    white-space: nowrap;
  }
  .text-value {
    font-weight: 500;
  }
  .row {
    white-space: nowrap;
    word-break: keep-all;
    margin-top: 15px;
  }
  .item {
    & + .item {
      margin-top: 15px;
    }
  }
</style>

<style lang="less">
  .resume-details-edu-continue {
    .ivu-timeline {
      display: block;
      width: 100%;
    }
  }
</style>

<template>
  <r-card hborder class="resume-details-edu-continue">
    <span slot="title">继续教育</span>
    <div class="content">
      <div>
        <div v-for="(item, index) in data" :key="item.id" class="item">
          <div class="text-value">{{index + 1}}. {{item.issueOrg}} - {{item.zsCode}}</div>
          <div class="row">
            <span class="text-field">发证日期：</span>
            <span class="text-value">{{(item.issueDate || '').substr(0, 10).replace(/\-/g, '/')}}</span>
            <span class="text-field" style="margin-left: 60px;">工作单位：</span>
            <span class="text-value">{{item.workeOrg}}</span>
            <span class="text-field" style="margin-left: 60px;">专业技术岗位和职务：</span>
            <span class="text-value">{{item.zyjsgw}} / {{ item.zyjszw }}</span>
          </div>
          <Timeline style="margin-top: 30px;" v-if="item.registerDataList && item.registerDataList.length">
            <TimelineItem v-for="(log, i) in item.registerDataList" :key="item.id + '.' + i">
              <div class="item">
                <div style="display: flex;width: 50vw;min-width: 700px;justify-content: space-between;">
                  <div>
                    <span class="text-field">项目名称：</span>
                    <span class="text-value">{{log.xmmc}}</span>
                    <!-- <span class="text-value">{{log.xmmc}}（{{ log.wcqk }}）</span> -->
                  </div>
                  <!-- <div>
                    <span class="text-field">学习时间：</span>
                    <span class="text-value">{{(log.startDate || '').substr(0, 10).replace(/\-/g, '/')}}—{{(log.endDate || (log.startDate ? '至今' : '')).substr(0, 10).replace(/\-/g, '/')}}</span>
                  </div> -->
                 <div>
                    <span class="text-field">所属年份：</span>
                    <span class="text-value">{{log.endDate ? log.endDate.slice(0, 4) : ''}}</span>
                  </div>
                  <div>
                    <span class="text-field">总学时：</span>
                    <span class="text-value">{{log.xsTotal}}</span>
                  </div>
                  <div>
                    <span class="text-field">总学分：</span>
                    <span class="text-value">{{log.xfTotal}}</span>
                  </div>
                </div>
                <!-- <div style="display: flex;width: 50vw;min-width: 700px;justify-content: space-between;margin-top: 20px;">
                   <div>
                    <span class="text-field">学习形式：</span>
                    <span class="text-value">{{log.xxxs}}</span>
                  </div>
                  <div>
                    <span class="text-field">总学时：</span>
                    <span class="text-value">{{log.xsTotal}}</span>
                  </div>
                  <div>
                    <span class="text-field">总学分：</span>
                    <span class="text-value">{{log.xfTotal}}</span>
                  </div>
                </div>  -->
                <!-- <div style="display: flex;width: 50vw;min-width: 700px;justify-content: space-between;margin-top: 20px;">
                  <div>
                    <span class="text-field">认定单位：</span>
                    <span class="text-value">{{log.rddw}}</span>
                  </div>
                  <div>
                    <span class="text-field">审核时间：</span>
                    <span class="text-value">{{(log.shsj || '').substr(0, 10).replace(/\-/g, '/')}}</span>
                  </div>
                  <div>
                    <span class="text-field">验证单位：</span>
                    <span class="text-value">{{log.yzdw}}</span>
                  </div>
                </div>-->
              </div>
            </TimelineItem>
          </Timeline>
          <r-imgs v-if="item.fileIds" :name="item.issueOrg" :covers="item.sources.covers" :previews="item.sources.previews" />
        </div>
      </div>
    </div>
  </r-card>
</template>

<script>
import RCard from '../components/card'
import RImgs from '../components/imgs'

import { request } from '@/api'
import { loadImgs } from '@/components/base/file/libv2'

export default {
  components: { RCard, RImgs },
  data () {
    return {
      data: []
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    }
  },
  watch: {
    id () {
      this.loadData()
    }
  },
  methods: {
    async loadData () {
      const data = await request('/basis/worker_edu_continue', 'get', { workerId: this.id })
      data.sort((a, b) => a.issueDate > b.issueDate)

      for (let item of data) {
        try {
          item.changDataList = item.changData ? JSON.parse(item.changData) : []
        } catch (e) {
          item.changDataList = []
        }
        try {
          item.registerDataList = item.registerData ? JSON.parse(item.registerData) : []
        } catch (e) {
          item.registerDataList = []
        }
        item.sources = await loadImgs([item], (item) => item.issueOrg || '继续教育')
      }

      console.log(data)

      this.data = data
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
